<!-- 导航条 -->
<template>
  <div class="header animate__animated animate__fadeInDown">
    <div class="container mx-auto flex justify-between height">
      <div class="logo flex items-center">
        <img src="../assets/img/text/logo.png" alt="" />
        <i class="i-3-text"></i>
      </div>
      <div class="flex mt-19">
        <div class="flex">
          <i class="i-1-text cursor-pointer" @click="regist"></i>
          <i class="i-2-text cursor-pointer" @click="login"></i>
        </div>
      </div>
    </div>
    <div class="nav-bar flex">
      <div class="bar"></div>
      <div class="container mx-auto flex justify-between items-center">
        <div
          class="item"
          :class="{ active: active == index }"
          v-for="(item, index) in lists"
          :key="index"
          @click="goTo(item.pathName, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "",
  setup() {
    const router = useRouter();

    const data = reactive({
      active: 0,
      lists: [
        {
          name: "首页",
          pathName: "index",
        },
        {
          name: "活动动态",
          pathName:"dynamics"
        },
        // {
        //   name: "团队招募",
        // },
        {
          name: "活动招募",
          pathName:"activityRecruitment"
        },
        {
          name: "活动公示",
           pathName:"activityAnnouncement"
        },
        {
          name: "志愿培训",
           pathName:"train"
        },
        {
          name: "精准对接",
           pathName:"preciseDocking"
        },
        {
          name: "实践阵地",
          pathName:"map"
        },
        {
          name: "爱心商城",
          pathName: "loveMall",
        },
        // {
        //   name: "文明实践",
        //   pathName: "practice",
        // },
      ],
      goTo: (pathName, index) => {
        data.active = index;
         router.push({ name: pathName });
      },
    });
   
    const regist = () => {
      router.push({ name: "registNotice" });
    }
    const login = () => {
      window.open("http://xw.zyzcloud.cn/xwzyz/login")
    }
    const refData = toRefs(data);
    return {
      ...refData,
      regist,
      login
    };
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
  .height {
    height: 0.88rem;
    overflow: hidden;
  }
}
.logo {
  height: 0.76rem;
  margin: 0.06rem 0;
  img {
    width: 0.83rem;
    height: 0.68rem;
  }
}

.nav-bar {
  position: relative;
  height: 0.59rem;

  .bar {
    position: absolute;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 0.59rem;
    background: linear-gradient(-90deg, #3eb5c6, #f0ac00, #f19355, #e85f69);
    opacity: 0.26;
  }
  .container {
    z-index: 9;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.34rem;
      height: 0.59rem;
      font-size: 0.18rem;
      font-weight: 500;
      color: #dd2d25;
      text-align: center;
      border-top: 0.04rem solid rgba(0, 0, 0, 0);
      border-bottom: 0.04rem solid rgba(0, 0, 0, 0);
      cursor: pointer;
    }
    .active {
      border-top: 0.04rem solid #e41919;
      border-bottom: 0.04rem solid rgba(0, 0, 0, 0);
    }
  }
}

.mt-19 {
  margin-top: 0.19rem;
}
.i-1-text {
  width: 1.52rem;
  height: 0.75rem;
  background: url(@/assets/img/text/i-1-text.png) no-repeat;
  background-size: 100%;
}
.i-2-text {
  width: 1.52rem;
  height: 0.75rem;
  background: url(@/assets/img/text/i-2-text.png) no-repeat;
  background-size: 100%;
}
.i-3-text {
  width: 4.73rem;
  height: 0.3rem;
  background: url(@/assets/img/text/i-3-text.png) no-repeat;
  background-size: 100%;
}
</style>
