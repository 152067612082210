(function (doc, win, undefined) {
    var docEl = doc.documentElement,
      resizeEvt = "orientationchange" in win ? "orientationchange" : "resize",
      recalc = function () {
        var clientWidth = docEl.clientWidth;
        if (clientWidth === undefined) return;
        if(clientWidth > 1200){
          docEl.style.fontSize = 100 * (1920 / 1920) + "px";
        }else{
          docEl.style.fontSize = 100 * (clientWidth / 1920) + "px";
        }
        
      };
    if (doc.addEventListener === undefined) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener("DOMContentLoaded", recalc, false);
  })(document, window);