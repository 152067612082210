<template>
  <el-config-provider :locale="locale">
  <header-view />
  <div class="container mx-auto">
    <router-view />
  </div>
  <footer-view /></el-config-provider>
</template>
<script>
import headerView from "./components/headerView.vue";
import footerView from "./components/footerView.vue";
import { ElConfigProvider } from 'element-plus'

  import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default {
  name: "app",
  components: { headerView, footerView,ElConfigProvider },
  setup() {
      return {
        locale: zhCn,
      }
    },
};
</script>
