<!-- 导航条 -->
<template>
  <ul class="filter">
    <li class="flex">
      <div class="title">所属区域：</div>
      <section class="content flex">
        <div
          class="item"
          :class="{ active: active == item.name }"
          v-for="(item, index) in areaList"
          :key="index"
          @click="chooseA(index, item.name)"
        >
          {{ item.name }}
        </div>
      </section>
    </li>
    <li class="flex">
      <div class="title">所属领域：</div>
      <section class="content flex flex-wrap">
        <div
          class="item"
          :class="{ active: active2 == item.name }"
          v-for="(item, index) in field"
          :key="index"
          @click="chooseF(index, item.name)"
        >
          {{ item.name }}
        </div>
      </section>
    </li>
  </ul>
</template>

<script>
import { reactive, toRefs, onBeforeMount } from "vue";
export default {
  name: "",
  props: ["area", "project"],
  setup(props, context) {
    const data = reactive({
      active: props.area,
      active2: props.project,
      areaList: [
        {
          name: "全部",
        },
        {
          name: "江溪街道",
        },
        {
          name: "梅村街道",
        },
        {
          name: "鸿山街道",
        },
        {
          name: "旺庄街道",
        },
        {
          name: "硕放街道",
        },
        {
          name: "新安街道",
        },
      ],
      field: [
        {
          name: "全部",
        },
        {
          name: "理论宣讲",
        },
        {
          name: "文化文艺",
        },
        {
          name: "科普宣传",
        },
        {
          name: "教育服务",
        },
        {
          name: "健身体育",
        },
        {
          name: "环保公益",
        },
        {
          name: "法律服务",
        },
        {
          name: "卫生健康",
        },
        {
          name: "吴韵巾帼",
        },
        {
          name: "平安护航",
        },
        {
          name: "同心同行",
        },
        {
          name: "其他项目",
        },
      ],
      areaName: props.area,
      fieldName: props.project,
    });
    const chooseA = (index, name) => {
      data.active = name;
      if (name == "全部") {
        data.areaName = "";
      } else {
        data.areaName = name;
      }

      context.emit("filter", data.areaName, data.fieldName);
    };
    const chooseF = (index, name) => {
      data.active2 = name;
      if (name == "全部") {
        data.fieldName = "";
      } else {
        data.fieldName = name;
      }
      context.emit("filter", data.areaName, data.fieldName);
    };

    onBeforeMount(() => {
      if (data.areaName == "全部") {
        data.areaName = "";
      } 
       if (data.fieldName == "全部"){
        data.fieldName = "";
      }
      context.emit("filter", data.areaName, data.fieldName);
    });
    const refData = toRefs(data);
    return {
      ...refData,
      chooseA,
      chooseF,
    };
  },
};
</script>
<style lang="scss" scoped>
.filter {
  margin: 0.3rem 0;
  font-size: 0.16rem;
  font-weight: 500;
  color: #333333;
  li {
    margin-bottom: 0.16rem;
  }
  .title {
    width: 1rem;
    padding-top: 0.02rem;
  }
  .content {
    width: calc(100% - 1rem);
    justify-content: flex-start;
  }
  .item {
    width: 1.08rem;
    margin: 0.02rem 0;
    padding: 0 0.22rem;
    height: 0.28rem;
    line-height: 0.28rem;
    cursor: pointer;
    text-align: center;
  }
  .item.active {
    background: #ec1717;
    border-radius: 4px;
    color: #fff;
  }
}
</style>
