<!-- 页脚 -->
<template>
  <div class="footer">
    <i class="i-1-back"></i>

    <div class="container mx-auto flex justify-between text-white">
      <div>
        <i class="i-10-text"></i>
        <p class="footer-1-text text-white text-opacity-80">
          中共无锡新吴区委宣传部主办 文明新吴©版权所有
        </p>
        <p class="footer-2-text text-white text-opacity-60 cursor-pointer" @click="goTo">
          备案编号：苏ICP备15037184号-3
        </p>
        <p class="text-white text-opacity-60">
          技术支持：苏州佐沂信息技术有限公司
        </p>
      </div>
      
      <div class="flex text-center">
        <div>
          <i class="i-1-qrcode"></i>
          <div class="mb-3 font-bold">扫一扫关注</div>
          <div class="text-red font-bold">文明新吴</div>
        </div>
        <div>
          <i class="i-2-qrcode"></i>
          <div class="mb-3 font-bold">扫一扫关注</div>
          <div class="text-red font-bold">三杯茶志愿者</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  name: "",
  setup() {
    const data = reactive({});
    const goTo = () =>{
      window.open("https://beian.miit.gov.cn/")
    }
    const refData = toRefs(data);
    return {
      ...refData,goTo
    };
  },
};
</script>
<style lang="scss" scoped>
.footer {
  height: 2.73rem;
  background: #1a273b;
  font-size: 0.14rem;
  .a {
    background: #000;
    height: 1rem;
  }
  .num {
    width: .26rem;
    height: .37rem;
    margin-right: .03rem;
    background: #c42323;
    border-radius: .03rem;
  }
  .footer-1-text {
    margin-bottom: 0.21rem;
    font-size: 0.16rem;
  }
  .footer-2-text {
    margin-bottom: 0.09rem;
  }
}
.mtl{
  margin-top: 1.62rem;
  margin-left: .53rem;
}
.mb-12 {
  margin-bottom: 0.12rem;
}
.mr-60 {
  margin-right: 0.6rem;
}
.mb-3{
  margin-bottom: 0.03rem; 
}
.i-1-back {
  width: 100%;
  height: 0.13rem;
  background: #000 url(@/assets/img/back/i-1-back.png) no-repeat;
  background-size: 100% 100%;
}
.i-10-text {
  width: 3.42rem;
  height: 0.72rem;
  margin: 0.39rem 0 0.16rem 0;
  background: url(@/assets/img/text/i-10-text.png) no-repeat;
  background-size: 100% 100%;
}
.i-1-qrcode {
  width: 1.46rem;
  height: 1.46rem;
  margin: 0.24rem 0 0.16rem 0;
  background: url(@/assets/img/qrcode/i-1-qrcode.jpg) no-repeat;
  background-size: 100% 100%;
}
.i-2-qrcode {
  //
  width: 1.46rem;
  height: 1.46rem;
   margin: 0.24rem 0 0.16rem 0;
    margin-left: 0.2rem;
  background: url(@/assets/img/qrcode/i-2-qrcode.jpg) no-repeat;
  background-size: 100% 100%;
}
</style>
