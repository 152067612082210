<!-- 导航条 -->
<template>
  <ul class="filter">
    <li class="flex">
      <div class="title">招募对象：</div>
      <section class="content flex">
        <div
          class="item"
          :class="{ active: active == item.name }"
          v-for="(item, index) in areaList"
          :key="index"
          @click="chooseA(index, item.name)"
        >
          {{ item.name }}
        </div>
      </section>
    </li>
    
  </ul>
</template>

<script>
import { reactive, toRefs, onBeforeMount } from "vue";
export default {
  name: "",
  props: ["zmdx"],
  setup(props, context) {
    const data = reactive({
      active: props.zmdx,
      areaList: [
        {
          name: "全部",
        },
        {
          name: "本团队志愿者",
        },
        {
          name: "所有志愿者",
        }
      ],
      
      areaName: props.zmdx,
    });
    const chooseA = (index, name) => {
      data.active = name;
      // if (name == "全部") {
      //   data.areaName = "";
      // } else {
        data.areaName = name;
      // }

      context.emit("filter", data.areaName);
    };

    onBeforeMount(() => {
      data.areaName == "全部";
      context.emit("filter", data.areaName);
    });
    const refData = toRefs(data);
    return {
      ...refData,
      chooseA,
    };
  },
};
</script>
<style lang="scss" scoped>
.filter {
  margin: 0.3rem 0;
  font-size: 0.16rem;
  font-weight: 500;
  color: #333333;
  li {
    margin-bottom: 0.16rem;
  }
  .title {
    width: 1rem;
    padding-top: 0.02rem;
  }
  .content {
    width: calc(100% - 1rem);
    justify-content: flex-start;
  }
  .item {
    min-width: 1.28rem;
    margin: 0.02rem 0;
    padding: 0 0.22rem;
    height: 0.28rem;
    line-height: 0.28rem;
    cursor: pointer;
    text-align: center;
  }
  .item.active {
    background: #ec1717;
    border-radius: 4px;
    color: #fff;
  }
}
</style>
