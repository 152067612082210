import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import {ArrowLeftBold,ArrowRightBold,ArrowLeft,ArrowRight} from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import './assets/scss/style.scss';
import './utils/useDraw'
import 'animate.css';
import currentPosition from "./components/currentPosition.vue";
import filterView from "./components/filterView.vue";
import filterView2 from "./components/filterView2.vue";
createApp(App).use(router).use(ElementPlus)
.component('arrowLeftBold',ArrowLeftBold).component('arrowRightBold',ArrowRightBold).component('arrow-left',ArrowLeft)
.component('arrow-right',ArrowRight).component('current-position',currentPosition).component('filter-view',filterView).component('filter-view-2',filterView2)
.mount('#app')
