<!-- 导航条 -->
<template>
  <div class="flex items-center box">
    <i class="i-text-13"></i>
    <span class="title" @click="home"
      >首页&ensp;>&ensp;
    </span>
    
    <span class="text"><slot></slot></span>
  </div>
  <div class="line"></div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "",
  setup() {
    const router = useRouter();
    const data = reactive({
      value: "",
    });
    const home = ()=>{
       router.push({ name: "index" });
    }
    const refData = toRefs(data);
    return {
      ...refData,home
    };
  },
};
</script>
<style lang="scss" scoped>
.box{
    padding: .32rem 0 .02rem 0;
}
.line{
    height: 3px;
    background: linear-gradient(-90deg, #0A4196, #0A6E28, #F08300, #FF0000, #E70202);;
    
}
.i-text-13 {
  width: 1.67rem;
  height: 0.39rem;
  background: url(@/assets/img/text/i-13-text.png) no-repeat;
  background-size: 100% 100%;
}
.title {
  margin-left: 0.16rem;
  font-size: 0.18rem;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
}
.text {
  font-size: 0.18rem;
  font-weight: 500;
    color: #e70202;
  }
</style>
